import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És una de les micenes més comuns i variables en quan a mida i color. Presenta un capell de 2 a 6 cm de diàmetre, convex i finalment aplanat amb un mamelló no massa marcat. La coloració com és variable des de lilaci a blavós amb els marges estriats per transparència en temps humits. Són higròfans, és a dir, típicament més obscurs quan més humits. Les làmines estan separades, amples, ventrudes, amb nombroses lamel·les i de color lila blanquinós amb l’aresta del mateix color. Presenta un peu de 2 a 8 cm per 2,5 a 5 mm més ample a la base, buit, llis i glabre. Les espores són blanques en massa, el·líptiques, de 6-8 x 3,5-4 micres.
Confusions: Una espècie molt semblant però de color rosa és la Mycena rosea (Bull.) Sacc. & Dalla Costa.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      